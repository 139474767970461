<template>
  <div class="elv-signup-container">
    <div class="elv-signup-content">
      <img src="@/assets/img/sign-in-logo.png" alt="logo" class="elv-signup-header-logo" />
      <div class="elv-signup-left-content">
        <p class="elv-signup-left-title">{{ t('message.loginInfoTitle') }}</p>
        <!-- <p class="elv-signup-left-info">{{ t('message.loginInfoDesc') }}</p> -->
        <img src="@/assets/img/login-left.webp" alt="" class="elv-login-left-img" />
      </div>
      <div class="elv-signup-right-content">
        <SignUp ref="signupRef" class="elv-login-right-signup" />

        <div v-if="isShowService" class="elv-signup-email-terms-service">
          {{ t('message.LoginAgreedTermsOfService') }}
          <a href="https://www.elven.com/terms-of-service" class="elv-signup-service-link" target="_blank">{{
            t('common.userService')
          }}</a
          >.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SignUp from '@/components/Sign/SignUp.vue'

const { t } = useI18n()

const signupRef = ref()

const isShowService = computed(() => {
  return signupRef.value?.isShowService
})
</script>
<style scoped lang="scss">
/* 小屏幕 768px-992px */
@media screen and (width <= 992px) {
  // background-color: pink;
}

/* 注意：此时超小屏幕和小屏幕在768px之间有冲突，怎么解决？
        把超小屏幕的区间降低1px */

/* 超小屏幕 768px屏幕以下 */
@media screen and (width <= 767px), screen and (max-width: 768px) {
  .elv-signup-container .elv-signup-content {
    position: absolute;
    // width: calc(100vw - 69px);
    height: fit-content;

    .elv-signup-header-logo {
      position: absolute;
      top: -77px;
      left: 50%;
      display: block !important;
      width: 154px;
      height: 54px;
      transform: translateX(-50%);
    }

    .elv-signup-left-content {
      display: none;
    }

    .elv-signup-right-content {
      width: fit-content;
      padding: 0;

      .elv-login-container.elv-login-right-signup {
        position: unset;
        width: 290px;
        transform: unset;
        // min-width: calc(100vw - 101px);

        :deep(.el-form) {
          .el-input.elv-login-input {
            width: 100%;
            min-width: 290px;
            // min-width: calc(100vw - 101px);
          }

          .el-form-item {
            &.elv-login-form-captcha {
              .el-input {
                width: 40px;
              }
            }

            &.elv-form-item-operating {
              margin-bottom: 0;
            }

            .elv-login-button__checkout {
              // min-width: calc(100vw - 101px);
              min-width: 290px;
            }
          }

          .elv-button.elv-login-continue,
          .el-button.elv-login-button--plain,
          .elv-button.elv-login-button,
          .elv-login-form-captcha__button,
          .elv-login-form-captcha__info {
            min-width: 290px;
            // min-width: calc(100vw - 101px);
          }
        }
      }

      .elv-signup-email-terms-service {
        position: unset;
        box-sizing: border-box;
        width: 290px;
        padding: 2px 4px;
        margin-top: 38px;
        font-size: 11px;
      }
    }
  }
}

.elv-signup-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #ebf4f9;
}

.elv-signup-content {
  position: relative;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  height: 582px;
  padding: 16px;
  background: #fff;
  border: 1px solid #dde1e6;
  border-radius: 10px;
  box-shadow:
    0 0 1px 0 rgb(0 0 0 / 10%),
    0 2px 15px 0 rgb(0 0 0 / 5%);
  transform: translate(-50%, -50%);

  .elv-signup-header-logo {
    display: none;
  }
}

.elv-signup-left-content {
  position: relative;
  box-sizing: border-box;
  display: flex;
  width: 460px;
  height: 100%;

  .elv-signup-left-img {
    display: block;
    width: 460px;
    height: 550px;
    margin-right: 0;
    border-radius: 5px 0 0 5px;
  }

  .elv-signup-left-logo {
    position: absolute;
    top: 25px;
    left: 24px;
    display: block;
    width: 114px;
    height: 40px;
  }

  .elv-signup-left-title {
    position: absolute;
    top: 170px;
    left: 24px;
    width: 332px;
    font-family: 'Plus Jakarta Sans';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    color: #000;
    text-transform: capitalize;
    letter-spacing: -0.72px;
  }

  .elv-signup-left-info {
    position: absolute;
    top: 210px;
    left: 24px;
    width: 323px;
    font-family: 'Plus Jakarta Sans';
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    color: #000;
  }
}

.elv-signup-right-content {
  position: relative;
  box-sizing: border-box;
  width: 379px;
  padding: 0 32px;

  .elv-login-right-signup {
    // padding-top: 131.5px;
    position: absolute;
    top: 45%;
    left: 32px;
    width: 315px;
    transform: translateY(-45%);
  }

  .elv-signup-email-terms-service {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 36px;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.64px;
    color: #838d95;
    text-align: center;

    .elv-signup-service-link {
      color: #838d95;
      text-decoration: underline;
    }
  }
}

.elv-sign-record-link {
  position: absolute;
  bottom: 20px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: #636b75;
  cursor: pointer;
  transform: translateX(-50%);

  &:hover {
    color: #1e2024;
  }
}
</style>
